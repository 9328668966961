document.querySelector('.main__nav--icon--wrapper').onclick = function() {
  this.classList.add('open');
  document.querySelector('.main__nav--wrapper').classList.add('open');
  document.querySelector('.main__nav--icon--close').classList.add('open');
  document.querySelectorAll('header')[0].classList.add('open');
}

document.querySelector('.main__nav--icon--wrapper--close').onclick = function() {
  this.classList.remove('open');
  document.querySelector('.main__nav--wrapper').classList.remove('open');
  document.querySelector('.main__nav--icon--close').classList.remove('open');
  document.querySelectorAll('header')[0].classList.remove('open');
}
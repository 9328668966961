// Scroll Header Toggle Show
function scrollDetect() {
  var lastScroll = 0;

  window.onscroll = function() {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
    let seitenbild = document.querySelector('.seitenbild');
    let actionButtonWrapper = document.querySelector('.action__button--wrapper');

    if (currentScroll > 290 && lastScroll <= currentScroll) {
      lastScroll = currentScroll;
      document.querySelector('.header__wrapper--nav').classList.add('scroll');
      document.querySelector('.main__nav--icon').classList.add('scroll');
      document.querySelector('.login__link--mobile').classList.add('scroll');
      document.querySelector('.header--logo--mobile').classList.add('scroll');
      document.querySelector('.header--logo--desktop').classList.add('scroll');
      document.querySelector('.header__sponsoren--wrapper').classList.add('scroll');

      if (typeof(seitenbild) != 'undefined' && seitenbild != null) {
        seitenbild.classList.add('scroll');
      }

      if (typeof(actionButtonWrapper) != 'undefined' && actionButtonWrapper != null) {
        actionButtonWrapper.classList.add('scroll');
      }

    } else {
      lastScroll = currentScroll;

      if (typeof(actionButtonWrapper) != 'undefined' && actionButtonWrapper != null) {
        actionButtonWrapper.classList.remove('scroll');
      }
    }

    if (currentScroll < 290) {
      document.querySelector('.header__wrapper--nav').classList.remove('scroll');
      document.querySelector('.main__nav--icon').classList.remove('scroll');
      document.querySelector('.login__link--mobile').classList.remove('scroll');
      document.querySelector('.header--logo--mobile').classList.remove('scroll');
      document.querySelector('.header--logo--desktop').classList.remove('scroll');
      document.querySelector('.header__sponsoren--wrapper').classList.remove('scroll');

      if (typeof(seitenbild) != 'undefined' && seitenbild != null) {
        seitenbild.classList.remove('scroll');
      }
    }
  };
}

scrollDetect();
// Importing scripts
import * as bootstrap from 'bootstrap';
import './js/csrf';
import './js/navigation';
import './js/scroll-header';
import './js/cookie-consent';
import './js/scripts';

// Importing styles (bootstrap + customization)
import './scss/_bootstrap.scss';
import './scss/styles.scss';

// Accept HMR
if (
  import.meta.hot) {
  import.meta.hot.accept(() => {});
}

window.bootstrap = bootstrap;
